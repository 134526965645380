import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Chip, Input, Slider, Tooltip, Typography } from "@material-tailwind/react";
import male_avatar_1 from '../assets/images/male_avatar_1.png';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Context } from './Constants';
import theme_sound from '../assets/sounds/aviator_song.mp3';


import paypal_icon from '../assets/images/paypal_icon.png';
import stripe_icon from '../assets/images/stripe_icon.png';
import mpesa_icon from '../assets/images/mpesa_icon.png';
import dusupay_icon from '../assets/images/dusupay_icon.png';
import credit_card_icon from '../assets/images/credit_card_icon.png';
import MelbetLogo from '../assets/images/crashbet.png';


import music_off from '../assets/images/music_off.png';
import music_on from '../assets/images/music_on.png';


import { ArrowPathIcon, TrashIcon, InformationCircleIcon, BanknotesIcon, Squares2X2Icon, ArrowLeftOnRectangleIcon, UserCircleIcon, FolderMinusIcon, Bars4Icon, UserPlusIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import useSound from 'use-sound';


import {
    Menu,
    MenuHandler,
    MenuList,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import Fair from './Fair';
import Login from './login';
import Register from './register';
import ManualPayment from './ManualPayment';
import StripeElements from './StripeElements';
import MpesaPayment from './MpesaPayment';
import DusupayPayment from './DusupayPayment';
import WingipayPayment from './WingipayPayment';
import PaypalPayment from './PaypalPayment';
import StripePayment from './StripePayment';
import Withdraw from './Withdraw';
import Profile from './Profile';
import ResetPassword from './ResetPassword';
import axios from 'axios';
import { setUserId } from '../features/userIdSlice';
import { setMyBets } from '../features/myBetsSlice';
import { ArrowsPointingOutIcon, CheckIcon, DocumentDuplicateIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { useCopyToClipboard } from 'usehooks-ts';
import { setAccountBalance } from '../features/accountBalanceSlice';
import { setAuthentication } from '../features/authenticationSlice';
import { setEmail } from '../features/emailSlice';
import { setUsername } from '../features/usernameSlice';
import { setMusic } from '../features/musicSlice';
import { setVolume } from '../features/volumeSlice';
import { Link } from '@mui/material';
axios.defaults.withCredentials = true;


export default function Options(props) {
    const TABLE_HEAD = ['Action', 'transaction_code', 'method', 'type', 'status', 'amount', 'details'];
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const authentication = useSelector((state) => state.authentication.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const userId = useSelector((state) => state.userId.value);
    const myBets = useSelector((state) => state.myBets.value);
    const username = useSelector((state) => state.username.value);
    const phone = useSelector((state) => state.phone.value);
    const email = useSelector((state) => state.email.value);
    const music = useSelector((state) => state.music.value);
    const volume = useSelector((state) => state.volume.value);


    const enableSlidingText = JSON.parse(localStorage.getItem('enableSlidingText'));
    const firstWhiteSlidingText = JSON.parse(localStorage.getItem('firstWhiteSlidingText'));
    const firstAmberSlidingText = JSON.parse(localStorage.getItem('firstAmberSlidingText'));
    const secondWhiteSlidingText = JSON.parse(localStorage.getItem('secondWhiteSlidingText'));
    const secondAmberSlidingText = JSON.parse(localStorage.getItem('secondAmberSlidingText'));
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const firstBannerImage = JSON.parse(localStorage.getItem('firstBannerImage'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));
    const secondBannerImage = JSON.parse(localStorage.getItem('secondBannerImage'));
    const dashboardUrl = JSON.parse(localStorage.getItem('dashboardUrl'));
    const clientSiteName = JSON.parse(localStorage.getItem('clientSiteName'));
    const clientSiteUrl = JSON.parse(localStorage.getItem('clientSiteUrl'));
    const cookiePolicyInfo = JSON.parse(localStorage.getItem('cookiePolicyInfo'));
    const terms = JSON.parse(localStorage.getItem('terms'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const currentYear = JSON.parse(localStorage.getItem('currentYear'));
    const directDepositGateway = JSON.parse(localStorage.getItem('directDepositGateway'));
    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const minimumWithdrawal = JSON.parse(localStorage.getItem('minimumWithdrawal'));
    const maximumWithdrawal = JSON.parse(localStorage.getItem('maximumWithdrawal'));
    const logo = JSON.parse(localStorage.getItem('logo'));
    const manualPayment = JSON.parse(localStorage.getItem('manualPayment'));
    const mpesaPayment = JSON.parse(localStorage.getItem('mpesaPayment'));
    const stripePayment = JSON.parse(localStorage.getItem('stripePayment'));
    const paypalPayment = JSON.parse(localStorage.getItem('paypalPayment'));
    const dusupayPayment = JSON.parse(localStorage.getItem('dusupayPayment'));
    const wingipayPayment = JSON.parse(localStorage.getItem('wingipayPayment'));
    const directDeposit = JSON.parse(localStorage.getItem('directDeposit'));
    const directDepositInstructions = JSON.parse(localStorage.getItem('directDepositInstructions'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const aboutAffiliateMarketing = JSON.parse(localStorage.getItem('aboutAffiliateMarketing'));
    const funModeAccountBalance = JSON.parse(localStorage.getItem('funModeAccountBalance'));
    const sportsBookName = JSON.parse(localStorage.getItem('sportsBookName'));
    const sportsBookUrl = JSON.parse(localStorage.getItem('sportsBookUrl'));


    const [processing, setProcessing] = useState(false);
    const [checkoutRequestID, setCheckoutRequestID] = useState('');
    const [mpesaPaymentProcessing, setMpesaPaymentProcessing] = useState(false);
    const [dusupayPaymentProcessing, setDusupayPaymentProcessing] = useState(false);
    const [wingipayPaymentProcessing, setWingipayPaymentProcessing] = useState(false);
    const [stripePaymentProcessing, setStripePaymentProcessing] = useState(false);
    const [transactionProcessing, setTransactionProcessing] = useState(false);
    const [allTransactions, setAllTransactions] = useState([]);
    const [processingTransactionId, setProcessingTransactionId] = useState();
    const [transactionDelete, setTransactionDelete] = useState(false);
    const [transactionConfirm, setTransactionConfirm] = useState(false);
    const [stripeProcessPayment, setStripeProcessPayment] = useState(false);





    const [value, copy] = useCopyToClipboard();
    const [copied, setCopied] = React.useState(false);

    const [affiliateLink, setAffiliateLink] = useState('');
    const [totalRevenueShare, setTotalRevenueShare] = useState('');
    const [totalReferrals, setTotalReferrals] = useState('');
    const [totalPendingAmount, setTotalPendingAmount] = useState('');
    const [totalApprovedAmount, setTotalApprovedAmount] = useState('');





    const affiliateWithdrawalAmountRef = useRef();
    const errRef = useRef();
    const withdrawRef = useRef();
    const [errMsg, setErrMsg] = useState([]);

    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [validWithdrawalAmount, setValidWithdrawalAmount] = useState(false);
    const [WithdrawalAmountFocus, setWithdrawalAmountFocus] = useState(false);

    const [withdrawalProcessing, setWithdrawalProcessing] = useState(false);

    const [play, { stop }] = useSound(theme_sound, { volume, autoplay: music, loop: true, interrupt: true, });



    const CUSTOM_ANIMATION = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };

    const [openDetails, setOpenDetails] = React.useState(0);
    const handleOpenDetails = (value) => setOpenDetails(openDetails === value ? 0 : value);




    const [openInfo, setOpenInfo] = React.useState(false);
    const handleOpenInfo = () => setOpenInfo(!openInfo);

    const [openDeposit, setOpenDeposit] = React.useState(false);
    const handleOpenDeposit = () => setOpenDeposit(!openDeposit);

    const [openWithdraw, setOpenWithdraw] = React.useState(false);
    const handleOpenWithdraw = () => setOpenWithdraw(!openWithdraw);

    const [openProfile, setOpenProfile] = React.useState(false);
    const handleOpenProfile = () => setOpenProfile(!openProfile);

    const [openManualPayment, setOpenManualPayment] = React.useState(false);
    const handleOpenManualPayment = () => setOpenManualPayment(!openManualPayment);

    const [openMpesaPayment, setOpenMpesaPayment] = React.useState(false);
    const handleOpenMpesaPayment = () => setOpenMpesaPayment(!openMpesaPayment);

    const [openDusupayPayment, setOpenDusupayPayment] = React.useState(false);
    const handleOpenDusupayPayment = () => setOpenDusupayPayment(!openDusupayPayment);

    const [openWingipayPayment, setOpenWingipayPayment] = React.useState(false);
    const handleOpenWingipayPayment = () => setOpenWingipayPayment(!openWingipayPayment);

    const [openPaypalPayment, setOpenPaypalPayment] = React.useState(false);
    const handleOpenPaypalPayment = () => setOpenPaypalPayment(!openPaypalPayment);

    const [openStripePayment, setOpenStripePayment] = React.useState(false);
    const handleOpenStripePayment = () => setOpenStripePayment(!openStripePayment);

    const [openTransactions, setOpenTransactions] = React.useState(false);
    const handleOpenTransactions = () => setOpenTransactions(!openTransactions);



    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    const handleMusic = () => {
        if (music === true) {
            dispatch(setMusic(false));
            localStorage.setItem('music', JSON.stringify(false));
            stop();
        } else {
            dispatch(setMusic(true));
            localStorage.setItem('music', JSON.stringify(true));
            play();
        }
    }

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }

    const adjustMusicVolume = (event) => {
        var newVolume = event.target.value / 100;
        dispatch(setVolume(newVolume));
        localStorage.setItem('volume', JSON.stringify(newVolume));
    };

    const handleDirectDeposit = () => {
        setOpenDeposit(() => true);
        if (directDepositGateway === 'mpesa_payment') {
            setOpenMpesaPayment(() => true);
        }
        if (directDepositGateway === 'marasoftpay_payment') {
            setOpenMpesaPayment(() => true);
        }
        if (directDepositGateway === 'dusupay_payment') {
            setOpenDusupayPayment(() => true);
        }
        if (directDepositGateway === 'wingipay_payment') {
            setOpenWingipayPayment(() => true);
        }
    }


    const handleCloseAllPaymentMethods = () => {
        setOpenManualPayment(() => false);
        setOpenMpesaPayment(() => false);
        setOpenDusupayPayment(() => false);
        setOpenWingipayPayment(() => false);
        setOpenPaypalPayment(() => false);
        setOpenStripePayment(() => false);
    }






















    const handleLogout = async (e) => {
        try {
            setProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/logout`,
                { userId: userId },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );

            dispatch(setUserId(999));
            dispatch(setEmail(''));
            dispatch(setUsername(''));
            dispatch(setMyBets([]));
            dispatch(setAccountBalance(response?.data.data.account_balance));
            dispatch(setAuthentication(false));
            localStorage.removeItem("crashToken");
            successNotify('You have been successfully logged out.');
            setProcessing(() => false);
        } catch (err) {

            dispatch(setUserId(999));
            dispatch(setEmail(''));
            dispatch(setUsername(''));
            dispatch(setAccountBalance(funModeAccountBalance));
            dispatch(setAuthentication(false));
            localStorage.removeItem("crashToken");
            successNotify('You have been successfully logged out.');

            setProcessing(() => false);
        }

    }


    const confirmMpesaPaymentFT = (id, checkoutRequestID) => {
        setTransactionConfirm(() => true);
        setProcessingTransactionId(() => id);
        setCheckoutRequestID(() => checkoutRequestID);
        setMpesaPaymentProcessing(() => true);
        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.post(`${baseUrl}/api/checkTransactionStatus`,
            { checkoutRequestID: checkoutRequestID },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )
            .then((response) => {
                dispatch(setAccountBalance(response?.data.data.account_balance));
                successNotify('Payment confirmed successfully.');
                setMpesaPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            }).catch((err) => {
                if (!err?.response) {
                    errorNotify('No Server Response');
                } else {
                    errorNotify(err.response.data.message);
                }
                setMpesaPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            });
    }



    const confirmDusupayPaymentFT = (id, checkoutRequestID) => {
        setTransactionConfirm(() => true);
        setProcessingTransactionId(() => id);
        setCheckoutRequestID(() => checkoutRequestID);
        setDusupayPaymentProcessing(() => true);
        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.post(`${baseUrl}/api/checkDusupayTransactionStatus`,
            { checkoutRequestID: checkoutRequestID },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )
            .then((response) => {
                dispatch(setAccountBalance(response?.data.data.account_balance));
                successNotify('Payment confirmed successfully.');
                setDusupayPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            }).catch((err) => {
                if (!err?.response) {
                    errorNotify('No Server Response');
                } else {
                    errorNotify(err.response.data.message);
                }
                setDusupayPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            });
    }




    const confirmWingipayPaymentFT = (id, checkoutRequestID) => {
        setTransactionConfirm(() => true);
        setProcessingTransactionId(() => id);
        setCheckoutRequestID(() => checkoutRequestID);
        setWingipayPaymentProcessing(() => true);
        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.post(`${baseUrl}/api/checkWingipayTransactionStatus`,
            { checkoutRequestID: checkoutRequestID },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )
            .then((response) => {
                dispatch(setAccountBalance(response?.data.data.account_balance));
                successNotify('Payment confirmed successfully.');
                setWingipayPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            }).catch((err) => {
                if (!err?.response) {
                    errorNotify('No Server Response');
                } else {
                    errorNotify(err.response.data.message);
                }
                setWingipayPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionConfirm(() => false);
                fetchTransactionsWCM();
            });
    }









    const deleteTransaction = (id) => {
        setTransactionDelete(() => true);
        setProcessingTransactionId(() => id);
        setMpesaPaymentProcessing(() => true);

        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.post(`${baseUrl}/api/deleteTransaction`,
            { userId: userId, id: id },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )
            .then((response) => {
                dispatch(setAccountBalance(response?.data.data.account_balance));
                successNotify('Transaction deleted successfully.');
                setMpesaPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionDelete(() => false);
                fetchTransactionsWCM();
            }).catch((err) => {
                if (!err?.response) {
                    errorNotify('No Server Response');
                } else {
                    errorNotify(err.response.data.message);
                }
                setMpesaPaymentProcessing(() => false);
                setProcessingTransactionId(() => null);
                setTransactionDelete(() => false);
                fetchTransactionsWCM();
            });

    }


    const confirmMpesaPayment = async (e) => {
        try {
            setMpesaPaymentProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/checkTransactionStatus`,
                { checkoutRequestID: checkoutRequestID },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            dispatch(setAccountBalance(response?.data.data.account_balance));
            handleCloseAllPaymentMethods();
            handleOpenDeposit();
            successNotify('Payment confirmed successfully.');
            setMpesaPaymentProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            setMpesaPaymentProcessing(() => false);
        }
    }


    const confirmDusupayPayment = async (e) => {
        try {
            setDusupayPaymentProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/checkDusupayTransactionStatus`,
                { checkoutRequestID: checkoutRequestID },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            dispatch(setAccountBalance(response?.data.data.account_balance));
            handleCloseAllPaymentMethods();
            handleOpenDeposit();
            successNotify('Payment confirmed successfully.');
            setDusupayPaymentProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            setDusupayPaymentProcessing(() => false);
        }
    }



    const confirmWingipayPayment = async (e) => {
        try {
            setWingipayPaymentProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/checkWingipayTransactionStatus`,
                { checkoutRequestID: checkoutRequestID },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            dispatch(setAccountBalance(response?.data.data.account_balance));
            handleCloseAllPaymentMethods();
            handleOpenDeposit();
            successNotify('Payment confirmed successfully.');
            setWingipayPaymentProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            setWingipayPaymentProcessing(() => false);
        }
    }







    const fetchTransactions = async (e) => {
        try {
            setTransactionProcessing(() => true);
            handleOpenTransactions();

            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/fetchTransactions`,
                { userId: userId },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setAllTransactions(() => response?.data);
            setTransactionProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            handleOpenTransactions();
            setTransactionProcessing(() => false);
        }
    }


    const fetchTransactionsWCM = async (e) => {
        try {
            setTransactionProcessing(() => true);

            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/fetchTransactions`,
                { userId: userId },

                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setAllTransactions(() => response?.data);
            setTransactionProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
            }
            setTransactionProcessing(() => false);
        }
    }
















    useEffect(() => {
        if (
            withdrawalAmount >= Number(minimumWithdrawal) &&
            withdrawalAmount <= Number(maximumWithdrawal) &&
            withdrawalAmount <= Number(props.availableReferralBalance)
        ) {
            setValidWithdrawalAmount(true);
        } else {
            setValidWithdrawalAmount(false);
        }
    }, [withdrawalAmount, minimumWithdrawal, maximumWithdrawal])

    const viewWithdrawal = () => {
        withdrawRef.current.scrollIntoView();
    }

    const handleAffiliateDirectWithdrawalSubmit = async (e) => {
        e.preventDefault();
        setWithdrawalProcessing(() => true);
        await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        const response = await axios.post(`${baseUrl}/api/affiliateDirectWithdrawalRequest`,
            { userId: userId, amount: withdrawalAmount },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )

        if (response?.data.errorCode != null) {
            errorNotify('Transaction Failed');
            setErrMsg(response?.data.errorMessage);
            errRef.current.scrollIntoView();
            setWithdrawalProcessing(() => false);
        } else {
            //  console.log(response);
            setWithdrawalProcessing(() => false);
            props.handleOpenAffiliate();
            successNotify('Withdrawal request initiated successfully.');
        }

    }







    //update affiliate constants
    useEffect(() => {

        setAffiliateLink(() => clientSiteUrl + '?affil=' + username);
        setTotalRevenueShare(() => (parseFloat(props.firstLevelAffiliatePercentage) + parseFloat(props.secondLevelAffiliatePercentage) + parseFloat(props.thirdLevelAffiliatePercentage)));
        setTotalReferrals(() => (parseFloat(props.firstLevelReferrals) + parseFloat(props.secondLevelReferrals) + parseFloat(props.thirdLevelReferrals)));
        setTotalPendingAmount(() => (parseFloat(props.firstLevelReferralPendingEarnings) + parseFloat(props.secondLevelReferralPendingEarnings) + parseFloat(props.thirdLevelReferralPendingEarnings)));
        setTotalApprovedAmount(() => (parseFloat(props.firstLevelReferralApprovedEarnings) + parseFloat(props.secondLevelReferralApprovedEarnings) + parseFloat(props.thirdLevelReferralApprovedEarnings)));


    }, [props]);






    return (
        <>


            <div>
                <div className="py-1 flex items-stretch gap-1 md:gap-2 w-full text-xs6 text-gray-300 ">
                    <div className="flex items-stretch flex-grow mx-1 ">

                        {window.DEMO_MODE === true &&
                            <>
                                <img
                                    src={MelbetLogo}
                                    alt="logo"
                                    width="230"
                                />
                            </>
                        }
                        {window.DEMO_MODE === false &&
                            <img
                                src={dashboardUrl + '/storage/' + logo}
                                alt="logo"
                                width="130"
                            />
                        }
                    </div>

                    <span className="block ">
                        <div>
                            
                            <a href={sportsBookUrl} className="flex justify-center p-1 md:my-0.5 border border-[#2e2e2e] rounded px-2 my-0.5 text-xs  font-medium  text-gray-50 bg-[#2e2e2e] hover:border-[#ffbc00] active:border-0 transition duration-700 ease-in-out">
                                <span className="mr-1">
                                    <GlobeAltIcon className=" h-6 text-[#ffbc00]" strokeWidth={2.0} />
                                </span>
                                <span className="hidden md:flex text-gray-200 text-xs pt-1 -pb-1" >{sportsBookName}</span>
                            </a>
                        </div>
                    </span>





                    <div className="flex w-fit flex-none">
                        <img className="h-7" src={male_avatar_1} alt="Profile" />
                        <div className="pt-1">
                            {authentication === false &&
                                <p className="text-xs leading-3 ">Fun Mode</p>
                            }
                            {authentication === true &&
                                <p className="text-xs leading-3 ">{username}</p>
                            }
                            <div className="text-xs text-[#ffbc00]">{formatAmount(accountBalance)}</div>
                        </div>
                    </div>




                    {authentication === false &&
                        <>
                            <button onClick={props.handleOpenAuthLogin} className="rounded px-2 my-0.5 text-xs  font-medium  text-gray-50 bg-[#2e2e2e] border border-[#ffbc00] hover:bg-[#ffbc00]  hover:text-[#111111] active:border-0 transition duration-700 ease-in-out">
                                <span className="hidden md:block">Log in</span>
                                <span className="md:hidden"><ArrowRightOnRectangleIcon className="h-6 text-[#ffbc00]" strokeWidth={2.0} /></span>
                            </button>
                            <button onClick={props.handleOpenAuthRegister} className="rounded px-2 my-0.5 text-xs  font-medium  text-[#111111] bg-[#ffbc00] border border-[#2e2e2e] hover:bg-[#2e2e2e] hover:border-[#ffbc00] hover:text-gray-50 active:border-0 transition duration-700 ease-in-out">
                                <span className="hidden md:block">Registration</span>
                                <span className="md:hidden"><UserPlusIcon className="h-6 text-[#111111]" strokeWidth={2.0} /></span>
                            </button>
                        </>
                    }
                    {authentication === true && directDeposit == false &&
                        <button onClick={handleOpenDeposit} className="rounded px-2 my-0.5 text-xs  font-medium  text-[#111111] bg-[#ffbc00] border border-[#2e2e2e] hover:bg-[#2e2e2e] hover:border-[#ffbc00] hover:text-gray-50 active:border-0 transition duration-700 ease-in-out">Deposit</button>
                    }
                    {authentication === true && directDeposit == true &&
                        <button onClick={handleDirectDeposit} className="rounded px-2 my-0.5 text-xs  font-medium  text-[#111111] bg-[#ffbc00] border border-[#2e2e2e] hover:bg-[#2e2e2e] hover:border-[#ffbc00] hover:text-gray-50 active:border-0 transition duration-700 ease-in-out">Deposit</button>
                    }

                    <div className="">
                        <Menu placement="bottom-end">
                            <MenuHandler>
                                <Bars4Icon className="h-8 w-8 text-white" strokeWidth={2.0} />
                            </MenuHandler>
                            <MenuList className="bg-[#212121] p-0 border-[#ffbc00]">
                                <>

                                    <div className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-2 m-1">
                                            <Fair
                                            />
                                    </div>

                                    <div className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-2 m-1">
                                        {music === true &&
                                            <button onClick={handleMusic} type='button'>
                                                <img className="text-gray-50 flex-none w-4" width={14} src={music_on} alt="Music" />
                                            </button>
                                        }
                                        {music === false &&
                                            <button onClick={handleMusic} type='button'>
                                                <img className="text-gray-300 flex-none w-5" width={14} src={music_off} alt="Music off" />
                                            </button>
                                        }
                                        <span className='pt-1'>
                                            <Slider onChange={adjustMusicVolume} id="musicVolumeSlider" name="musicVolumeSlider" className="flex-none min-w-[120px] " size="sm" value={volume * 100} />
                                        </span>
                                    </div>
                                </>

                                {authentication === false &&
                                    <>
                                        <div onClick={props.handleOpenAuthLogin} className="md:hidden flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                            <button type="button" onClick={props.handleOpenAuthLogin}>
                                                <ArrowRightOnRectangleIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                            </button>
                                            <button type="button" onClick={props.handleOpenAuthLogin} className="text-xs pt-1 text-gray-200 w-full">
                                                Log in
                                            </button>
                                        </div>
                                        <div onClick={props.handleOpenAuthRegister} className="md:hidden flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                            <button type="button" onClick={props.handleOpenAuthRegister}>
                                                <UserPlusIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                            </button>
                                            <button type="button" onClick={props.handleOpenAuthRegister} className="text-xs pt-1 text-gray-200 w-full">
                                                Sign Up
                                            </button>
                                        </div>

                                    </>
                                }

                                <div onClick={handleOpenInfo} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                    <button type="button" onClick={handleOpenInfo}>
                                        <InformationCircleIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                    </button>
                                    <button type="button" onClick={handleOpenInfo} className="text-xs pt-1 text-gray-200 w-full">
                                        About this Game
                                    </button>
                                </div>

                                {authentication === true &&
                                    <div onClick={handleOpenWithdraw} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button onClick={handleOpenWithdraw} type="button" >
                                            <FolderMinusIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                        </button>
                                        <button onClick={handleOpenWithdraw} type="button" className="text-xs pt-1 text-gray-200  w-full">
                                            Withdraw
                                        </button>
                                    </div>
                                }

                                {authentication === true &&
                                    <div onClick={fetchTransactions} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button onClick={fetchTransactions} type="button" >
                                            <BanknotesIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                        </button>
                                        <button onClick={fetchTransactions} type="button" className="text-xs pt-1 text-gray-200  w-full">
                                            Transactions
                                        </button>
                                    </div>
                                }

                                {authentication === true &&
                                    <div onClick={handleOpenProfile} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button onClick={handleOpenProfile} type="button" >
                                            <UserCircleIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                        </button>
                                        <button onClick={handleOpenProfile} type="button" className="text-xs pt-1 text-gray-200  w-full">
                                            Profile
                                        </button>
                                    </div>
                                }

                                {authentication === true &&
                                    <div onClick={props.fetchReferralInfo} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button onClick={props.fetchReferralInfo} type="button" >
                                            <ArrowsPointingOutIcon className="mt-1 h-6 w-6 text-white" strokeWidth={2.0} />
                                        </button>
                                        <button onClick={props.fetchReferralInfo} type="button" className="text-xs pt-1 text-gray-200  w-full">
                                            Refer & Earn
                                        </button>
                                    </div>
                                }

                                {(authentication === true) && (processing === false) &&
                                    <div onClick={handleLogout} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button type="button" onClick={handleLogout}>
                                            <ArrowLeftOnRectangleIcon className="mt-1 h-7 w-7 text-white" strokeWidth={2.0} />
                                        </button>
                                        <button type="button" onClick={handleLogout} className="text-xs pt-1 text-gray-200  w-full">
                                            Logout
                                        </button>
                                    </div>
                                }

                                {(authentication === true) && (processing === true) &&
                                    <div onClick={handleLogout} className="flex gap-2 bg-[#2e2e2e] hover:bg-[#ffbc00] rounded p-1 m-1">
                                        <button type="button" onClick={handleLogout}>
                                            <svg className="animate-spin h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </button>
                                        <button type="button" onClick={handleLogout} className="text-xs pt-1 text-gray-200 w-full">
                                            Processing...
                                        </button>
                                    </div>
                                }


                            </MenuList>

                        </Menu>
                    </div>


                </div>


                {stripeProcessPayment === true &&
                    <>
                        {stripePayment == true &&

                            <StripeElements

                                stripeProcessPayment={stripeProcessPayment}
                                setStripeProcessPayment={setStripeProcessPayment}

                            />
                        }
                    </>
                }


                <Dialog
                    open={openInfo}
                    handler={handleOpenInfo}

                    size={"xs"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">Info</DialogHeader>


                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">


                        <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: localStorage.getItem('aboutGameInfo') }} />


                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleOpenInfo}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="green" onClick={handleOpenInfo}>
                            <span>Confirm</span>
                        </Button>
                    </DialogFooter>
                </Dialog>


                <Dialog
                    open={props.openAuth}
                    handler={props.handleOpenAuth}
                    size={"xs"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">
                        {props.openLogin === true &&
                            <span>Login</span>
                        }
                        {props.openLogin === false &&
                            <span>Register</span>
                        }

                    </DialogHeader>


                    <DialogBody divider className="h-auto overflow-y-scroll text-xs text-gray-300">


                        {props.openLogin === true &&
                            <Login
                                {...props}



                            />
                        }

                        {props.openLogin === false &&
                            <Register
                                {...props}


                            />
                        }

                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={props.handleOpenAuth}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>


                <Dialog
                    open={openDeposit}
                    handler={handleOpenDeposit}

                    size={"xs"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">
                        {openManualPayment === true &&
                            <span>Manual Payment</span>
                        }
                        {openMpesaPayment === true &&
                            <span>Mpesa Payment</span>
                        }
                        {openDusupayPayment === true &&
                            <span>Dusupay Payment</span>
                        }
                        {openWingipayPayment === true &&
                            <span>Wingipay Payment</span>
                        }
                        {openStripePayment === true &&
                            <span>Stripe Payment</span>
                        }
                        {openPaypalPayment === true &&
                            <span>Paypal Payment</span>
                        }
                        {((openManualPayment === false) && (openMpesaPayment === false) && (openDusupayPayment === false) && (openWingipayPayment === false) && (openStripePayment === false) && (openPaypalPayment === false)) &&
                            <span>Select Deposit Method</span>
                        }

                    </DialogHeader>


                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">


                        {((openManualPayment === false) && (openMpesaPayment === false) && (openDusupayPayment === false) && (openWingipayPayment === false) && (openStripePayment === false) && (openPaypalPayment === false)) &&

                            <div className="flex flex-col gap-4">
                                {manualPayment == true &&
                                    <Button onClick={handleOpenManualPayment} ripple={true} size="lg" color="white" className="w-full">
                                        <span className="flex gap-2 items-center w-full justify-center">
                                            <BanknotesIcon className=" h-7 w-7 text-black" strokeWidth={2.0} />
                                            Manual Deposit
                                        </span>
                                    </Button>
                                }
                                {mpesaPayment == true &&
                                    <Button onClick={handleOpenMpesaPayment} ripple={true} size="lg" color="white" className="py-0 flex w-full justify-center">
                                        <img className="h-14 my-0" src={mpesa_icon} alt="Mpesa Deposit" />
                                    </Button>
                                }
                                {dusupayPayment == true &&
                                    <Button onClick={handleOpenDusupayPayment} ripple={true} size="lg" color="white" className="py-0 flex w-full justify-center">
                                        <img className="h-14 my-0" src={dusupay_icon} alt="Dusupay Deposit" />
                                    </Button>
                                }
                                {wingipayPayment == true &&
                                    <Button onClick={handleOpenWingipayPayment} ripple={true} size="lg" color="white" className="py-0 flex w-full justify-center">
                                        <img className="h-14 my-0" src={dusupay_icon} alt="Wingipay Deposit" />
                                    </Button>
                                }
                                {stripePayment == true &&
                                    <Button onClick={handleOpenStripePayment} ripple={true} size="lg" color="white" className="gap-4 py-0 flex w-full items-center justify-center">
                                        <img className="h-14 my-0" src={stripe_icon} alt="Stripe Deposit" />
                                        <img className="h-8 my-0" src={credit_card_icon} alt="Visa Card, Master Card, American Express, Discover" />
                                    </Button>
                                }
                                {paypalPayment == true &&
                                    <Button onClick={handleOpenPaypalPayment} ripple={true} size="lg" color="white" className="gap-4 flex w-full items-center justify-center">
                                        <img className="h-7" src={paypal_icon} alt="Paypal Deposit" />
                                        <img className="h-7" src={credit_card_icon} alt="Visa Card, Master Card, American Express, Discover" />
                                    </Button>
                                }
                            </div>
                        }

                        {((openManualPayment === true) || (openDusupayPayment === true) || (openStripePayment === true) || (openPaypalPayment === true)) &&
                            <Button onClick={handleCloseAllPaymentMethods} fullWidth={true} color="amber" className="my-2">Select a different payment method</Button>
                        }


                        {openManualPayment === true &&
                            <ManualPayment
                                handleCloseAllPaymentMethods={handleCloseAllPaymentMethods}
                                handleOpenDeposit={handleOpenDeposit}


                            />
                        }

                        {openMpesaPayment === true &&
                            <MpesaPayment
                                handleCloseAllPaymentMethods={handleCloseAllPaymentMethods}
                                handleOpenDeposit={handleOpenDeposit}
                                setMpesaPaymentProcessing={setMpesaPaymentProcessing}
                                mpesaPaymentProcessing={mpesaPaymentProcessing}
                                setCheckoutRequestID={setCheckoutRequestID}
                                confirmMpesaPayment={confirmMpesaPayment}




                            />
                        }

                        {openDusupayPayment === true &&
                            <DusupayPayment
                                dusupayPaymentProcessing={dusupayPaymentProcessing}
                                setDusupayPaymentProcessing={setDusupayPaymentProcessing}
                                handleCloseAllPaymentMethods={handleCloseAllPaymentMethods}
                                handleOpenDeposit={handleOpenDeposit}
                                setCheckoutRequestID={setCheckoutRequestID}
                                confirmDusupayPayment={confirmDusupayPayment}



                            />
                        }

                        {openWingipayPayment === true &&
                            <WingipayPayment
                                wingipayPaymentProcessing={wingipayPaymentProcessing}
                                setWingipayPaymentProcessing={setWingipayPaymentProcessing}
                                handleCloseAllPaymentMethods={handleCloseAllPaymentMethods}
                                handleOpenDeposit={handleOpenDeposit}
                                setCheckoutRequestID={setCheckoutRequestID}
                                confirmWingipayPayment={confirmWingipayPayment}



                            />
                        }

                        {openStripePayment === true &&
                            <StripePayment
                                stripePaymentProcessing={stripePaymentProcessing}
                                setStripePaymentProcessing={setStripePaymentProcessing}
                                handleCloseAllPaymentMethods={handleCloseAllPaymentMethods}
                                handleOpenDeposit={handleOpenDeposit}


                            />
                        }

                        {openPaypalPayment === true &&
                            <PaypalPayment



                            />
                        }


                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleOpenDeposit}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>


                <Dialog
                    open={openWithdraw}
                    handler={handleOpenWithdraw}

                    size={"xs"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">

                        <span>Withdraw Funds</span>

                    </DialogHeader>


                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">



                        <Withdraw
                            handleOpenWithdraw={handleOpenWithdraw}


                        />




                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleOpenWithdraw}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>



                <Dialog
                    open={openTransactions}
                    handler={handleOpenTransactions}

                    size={"md"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">

                        <span>Transactions</span>

                    </DialogHeader>

                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">


                        {(transactionProcessing === true) &&
                            <Button ripple={true} size="lg" color="blue" className="w-full">
                                <span className="flex gap-2 items-center w-full justify-center">
                                    <svg className="animate-spin h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Loading...
                                </span>
                            </Button>
                        }

                        {(transactionProcessing === false) &&
                            <table className="w-full min-w-max table-auto text-left bg-indigo-910">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th key={head} className="border-b bg-indigo-900 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTransactions.map(({ id, transaction_code, method, type, status, amount, details }, index) => {
                                        const classes = "px-1 py-0 bg-indigo-800 border-b-2 border-indigo-900";

                                        return (
                                            <tr key={id} className="rounded-md  ">
                                                <td className={classes}>

                                                    <Button onClick={() => confirmMpesaPaymentFT(id, transaction_code)} disabled={((method === 'mpesa') && (status === 'pending')) ? false : true} size="sm" className='mx-1 py-1' color="green">
                                                        {mpesaPaymentProcessing === true && processingTransactionId === id && transactionConfirm === true &&
                                                            <span className="flex items-center justify-center gap-2 mx-auto">
                                                                <ArrowPathIcon className=" animate-spin h-5 w-5 text-white" strokeWidth={2.0} />
                                                                Processing...
                                                            </span>
                                                        }
                                                        {processingTransactionId !== id && transactionConfirm !== true &&
                                                            <span>Confirm</span>
                                                        }
                                                    </Button>

                                                    <Button onClick={() => confirmDusupayPaymentFT(id, transaction_code)} disabled={((method === 'dusupay') && (status === 'pending')) ? false : true} size="sm" className='mx-1 py-1' color="green">
                                                        {dusupayPaymentProcessing === true && processingTransactionId === id && transactionConfirm === true &&
                                                            <span className="flex items-center justify-center gap-2 mx-auto">
                                                                <ArrowPathIcon className=" animate-spin h-5 w-5 text-white" strokeWidth={2.0} />
                                                                Processing...
                                                            </span>
                                                        }
                                                        {processingTransactionId !== id && transactionConfirm !== true &&
                                                            <span>Confirm</span>
                                                        }
                                                    </Button>

                                                    <Button onClick={() => confirmWingipayPaymentFT(id, transaction_code)} disabled={((method === 'wingipay') && (status === 'pending')) ? false : true} size="sm" className='mx-1 py-1' color="green">
                                                        {wingipayPaymentProcessing === true && processingTransactionId === id && transactionConfirm === true &&
                                                            <span className="flex items-center justify-center gap-2 mx-auto">
                                                                <ArrowPathIcon className=" animate-spin h-5 w-5 text-white" strokeWidth={2.0} />
                                                                Processing...
                                                            </span>
                                                        }
                                                        {processingTransactionId !== id && transactionConfirm !== true &&
                                                            <span>Confirm</span>
                                                        }
                                                    </Button>

                                                    <Button onClick={() => deleteTransaction(id)} disabled={status !== 'pending' ? true : false} size="sm" className='mx-1 py-1' color="red">
                                                        {mpesaPaymentProcessing === true && processingTransactionId === id && transactionDelete === true &&
                                                            <span className="flex items-center justify-center gap-2 mx-auto">
                                                                <ArrowPathIcon className=" animate-spin h-5 w-5 text-white" strokeWidth={2.0} />
                                                                Processing...
                                                            </span>
                                                        }
                                                        {processingTransactionId !== id && transactionDelete !== true &&
                                                            <TrashIcon className="h-5 w-5 text-white" strokeWidth={2.0} />
                                                        }
                                                    </Button>

                                                </td>
                                                <td className={classes}>
                                                    {transaction_code !== null &&

                                                        <Typography className="text-xs  max-w-[150px] break-all">
                                                            {transaction_code}
                                                        </Typography>
                                                    }
                                                </td>
                                                <td className={classes}>
                                                    <span className='font-bold text-gray-200 uppercase'>{method}</span>
                                                </td>
                                                <td className={classes}>
                                                    {type === 'withdrawal' &&
                                                        <Chip
                                                            value="Withdrawal"
                                                            variant="outlined"
                                                            color="cyan"
                                                        />
                                                    }
                                                    {type === 'deposit' &&
                                                        <Chip
                                                            value="Deposit"
                                                            variant="outlined"
                                                            color="yellow"
                                                        />
                                                    }
                                                </td>
                                                <td className={classes}>
                                                    {status === 'pending' &&
                                                        <Chip
                                                            value="Pending"
                                                            color="blue"
                                                        />
                                                    }

                                                    {status === 'approved' &&
                                                        <Chip
                                                            value="Approved"
                                                            color="green"
                                                        />
                                                    }

                                                    {status === 'canceled' &&
                                                        <Chip
                                                            value="Canceled"
                                                            color="yellow"
                                                        />
                                                    }

                                                    {status === 'failed' &&
                                                        <Chip
                                                            value="Failed"
                                                            color="red"
                                                        />
                                                    }

                                                </td>
                                                <td className={classes}>
                                                    {amount !== null &&

                                                        <Typography variant="small" color="amber" className="text-xs font-medium">
                                                            {formatAmount(amount)}
                                                        </Typography>
                                                    }
                                                </td>
                                                <td className={classes}>
                                                    {details !== null &&
                                                        <Accordion open={openDetails === id} animate={CUSTOM_ANIMATION} className='w-36'>
                                                            <AccordionHeader onClick={() => handleOpenDetails(id)} className='text-gray-300 hover:text-gray-200 text-xs font-bold'>Details</AccordionHeader>
                                                            <AccordionBody className="text-gray-300">
                                                                {details}
                                                            </AccordionBody>
                                                        </Accordion>
                                                    }
                                                </td>


                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        }

                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleOpenTransactions}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>




                <Dialog
                    open={props.openAffiliate}
                    handler={props.handleOpenAffiliate}

                    size={"md"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">

                        <span>Refer & Earn</span>

                    </DialogHeader>

                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">


                        {(props.referralProcessing === true) &&
                            <Button ripple={true} size="lg" color="blue" className="w-full">
                                <span className="flex gap-2 items-center w-full justify-center">
                                    <svg className="animate-spin h-7 w-7 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Loading...
                                </span>
                            </Button>
                        }

                        {(props.referralProcessing === false) &&
                            <>

                                <Typography
                                    className="text-center border-r border-gray-900 pr-3 font-normal w-full m-auto"
                                    variant="small"
                                >
                                    &#8595;&#8595;Copy your referral link here&#8595;&#8595;
                                </Typography>
                                <Button
                                    onMouseLeave={() => setCopied(false)}
                                    onClick={() => {
                                        copy(affiliateLink);
                                        setCopied(true);
                                    }}
                                    className="flex items-center gap-x-3 px-4 py-2.5 my-2 lowercase m-auto"
                                    ripple={true}
                                    color="amber"
                                >
                                    <Typography
                                        className="border-r border-gray-900 pr-3 font-normal"
                                        variant="small"
                                    >
                                        {affiliateLink}
                                    </Typography>
                                    {copied ? (
                                        <>
                                            <CheckIcon className="h-4 w-4 text-black" /> Copied
                                        </>
                                    ) : (
                                        <>
                                            <DocumentDuplicateIcon className="h-4 w-4 text-black" /> Copy
                                        </>
                                    )}
                                </Button>
                                <Button
                                    onClick={viewWithdrawal}
                                    className="flex items-center gap-x-3 px-4 capitalize m-auto"
                                    ripple={true}
                                    color="indigo"
                                >
                                    <Typography
                                        className="font-normal"
                                    >
                                        Withdraw Earnings
                                    </Typography>

                                </Button>

                                <Typography
                                    className="mt-4 text-center border-r border-gray-900 pr-3 font-normal w-full uppercase "
                                    variant="small"
                                >
                                    Referral Statistics
                                </Typography>



                                <table className="w-full min-w-max table-auto text-left bg-indigo-910">
                                    <thead>
                                        <tr>
                                            <th className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">

                                            </th>
                                            <th className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    1st Level
                                                </Typography>
                                            </th>
                                            <th className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    2nd Level
                                                </Typography>
                                            </th>
                                            <th className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    3rd Level
                                                </Typography>
                                            </th>
                                            <th className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Total
                                                </Typography>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Rev Share %
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.firstLevelAffiliatePercentage}%
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.secondLevelAffiliatePercentage}%
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.thirdLevelAffiliatePercentage}%
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {totalRevenueShare}%
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Referrals
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.firstLevelReferrals}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.secondLevelReferrals}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {props.thirdLevelReferrals}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {totalReferrals}
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Pending Amount
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.firstLevelReferralPendingEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.secondLevelReferralPendingEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.thirdLevelReferralPendingEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {formatAmount(totalPendingAmount)}
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Approved Amount
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.firstLevelReferralApprovedEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.secondLevelReferralApprovedEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">
                                                {formatAmount(props.thirdLevelReferralApprovedEarnings)}
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {formatAmount(totalApprovedAmount)}
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Bonus Amount
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {formatAmount(props.bonusReferralEarnings)}
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Amount Paid
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {formatAmount(props.refferalAmountPaid)}
                                            </td>
                                        </tr>

                                        <tr className="rounded-md  ">
                                            <td className="border-b bg-indigo-700 p-2 text-sm text-gray-200 uppercase">
                                                <Typography
                                                    variant="small"
                                                    color="inherit"
                                                    className="text-xs leading-none font-medium"
                                                >
                                                    Available Balance
                                                </Typography>
                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700">

                                            </td>
                                            <td className="px-1 py-0 bg-indigo-900 border-b-2 border-indigo-700 font-medium">
                                                {formatAmount(props.availableReferralBalance)}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>





                                <div className="mt-10">




                                    <Card className="mx-auto w-full max-w-[24rem] my-4">

                                        <CardHeader
                                            variant="gradient"
                                            color="indigo"
                                            ref={withdrawRef}
                                            className="mb-4 grid h-16 place-items-center"
                                        >
                                            <Typography variant="h5" color="white">
                                                Withdraw Earnings
                                            </Typography>
                                        </CardHeader>
                                        <CardBody className="pt-0 flex flex-col gap-4">


                                            <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                                                {errMsg !== '' &&
                                                    <li>{errMsg}<br /></li>
                                                }

                                            </ul>





                                            <Input
                                                variant="outlined"
                                                label="Amount"
                                                size="lg"
                                                type="number"
                                                id="amount"
                                                ref={affiliateWithdrawalAmountRef}
                                                autoComplete="off"
                                                aria-invalid={validWithdrawalAmount ? "false" : "true"}
                                                aria-describedby="withdrawalAmountNote"
                                                onChange={(e) => setWithdrawalAmount(e.target.value)}
                                                value={withdrawalAmount}
                                                onFocus={() => setWithdrawalAmountFocus(true)}
                                                onBlur={() => setWithdrawalAmountFocus(false)}
                                                error={withdrawalAmount && !validWithdrawalAmount ? true : false}
                                                success={validWithdrawalAmount ? true : false}
                                                required
                                            />

                                            <Typography
                                                id="withdrawalAmountNote"
                                                variant="small"
                                                color="red"
                                                className={"mt-2 flex items-center gap-1 font-normal" + affiliateWithdrawalAmountRef && withdrawalAmount && !validWithdrawalAmount ? "flex" : "hidden"}
                                            >
                                                Must be greater than {minimumWithdrawal}! <br />
                                                Must be less than {maximumWithdrawal}! <br />
                                                Must be less or equal to your earnings, {props.AcceptavailableReferralBalance}! <br />
                                            </Typography>



                                        </CardBody>
                                        <CardFooter className="pt-0">
                                            {withdrawalProcessing === true &&
                                                <Button disabled={true} color='indigo' variant="gradient" fullWidth>
                                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Processing...
                                                    </span>
                                                </Button>
                                            }
                                            {withdrawalProcessing === false &&
                                                <Button onClick={handleAffiliateDirectWithdrawalSubmit} disabled={!validWithdrawalAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                                                    Submit
                                                </Button>
                                            }
                                        </CardFooter>
                                    </Card>

                                    <div className="grid grid-cols-2 p-2 gap-2 text-sm w-full text-orange-200 border rounded">
                                        <span className="w-full">
                                            Minimum Withdrawal: <b>{formatAmount(minimumWithdrawal)}</b>
                                        </span>
                                        <span className="w-full">
                                            Maximum Withdrawal: <b>{formatAmount(maximumWithdrawal)}</b>
                                        </span>
                                    </div>

                                </div>







                                <Typography
                                    className="mt-4 text-center border-r border-gray-900 pr-3 font-normal w-full uppercase "
                                    variant="small"
                                >
                                    About Affiliate Marketing
                                </Typography>
                                <div className=' text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: aboutAffiliateMarketing }} />



                            </>
                        }

                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={props.handleOpenAffiliate}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>





                <Dialog
                    open={openProfile}
                    handler={handleOpenProfile}

                    size={"md"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">

                        <span>Profile</span>

                    </DialogHeader>

                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">

                        <Profile

                            handleOpenProfile={handleOpenProfile}
                        />


                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleOpenProfile}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>


                <Dialog
                    open={props.openPasswordReset}
                    handler={props.handleOpenPasswordReset}

                    size={"md"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300">

                        <span>Password Reset</span>

                    </DialogHeader>

                    <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">

                        <ResetPassword
                            {...props}


                        />


                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={props.handleOpenPasswordReset}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                    </DialogFooter>
                </Dialog>

            </div>




        </>
    )
}
