import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import CrashGame from './CrashGame';
import { BallTriangle } from 'react-loader-spinner';
import useSound from 'use-sound';
import theme_sound from '../assets/sounds/aviator_song.mp3';



import axios from 'axios';
import { setPreviousGames } from '../features/previousGamesSlice';
import { setAccountBalance } from '../features/accountBalanceSlice';
import { setAuthentication } from '../features/authenticationSlice';
import { setUserId } from '../features/userIdSlice';
import { setMyBets } from '../features/myBetsSlice';
import { setMessages } from '../features/messagesSlice';
import { setLiveBets } from '../features/liveBetsSlice';
import { setEmail } from '../features/emailSlice';
import { setUsername } from '../features/usernameSlice';
import { setPhone } from '../features/phoneSlice';
import { setName } from '../features/nameSlice';
import { setMusic } from '../features/musicSlice';
import { setVolume } from '../features/volumeSlice';
import { setGameMode } from '../features/gameModeSlice';
import { setEncryptionKey } from '../features/encryptionKeySlice';

axios.defaults.withCredentials = true;
export const Context = React.createContext();


const Constants = () => {


    const dispatch = useDispatch();
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);




    const [screenLoading, setScreenLoading] = useState(true);
    const [gameCurrency, setGameCurrency] = useState('USD');
    const [gameSmBgImage, setGameSmBgImage] = useState('');
    const [gameLgBgImage, setGameLgBgImage] = useState('');
    const [hideNumberOfPlayers, setHideNumberOfPlayers] = useState(false);
    const [hideTotalAmount, setHideTotalAmount] = useState(false);
    const [hideTotalWinnings, setHideTotalWinnings] = useState(false);




    useEffect(() => {
        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        axios.get(`${baseUrl}/api/initializeApp/`, {})
            .then((response) => {




                //  setGameMusic(() => response.data.data.settings.game_music);
                setGameSmBgImage(() => response.data.data.settings.game_sm_bg_image);
                setGameLgBgImage(() => response.data.data.settings.game_lg_bg_image);



                


                setHideNumberOfPlayers(() => response.data.data.settings.hide_number_of_players);
                setHideTotalAmount(() => response.data.data.settings.hide_total_amount);
                setHideTotalWinnings(() => response.data.data.settings.hide_total_winnings);

                




                localStorage.setItem('enableSlidingText', JSON.stringify(response.data.data.settings.enable_sliding_text))
                localStorage.setItem('firstWhiteSlidingText', JSON.stringify(response.data.data.settings.first_white_sliding_text))
                localStorage.setItem('firstAmberSlidingText', JSON.stringify(response.data.data.settings.first_amber_sliding_text))
                localStorage.setItem('secondWhiteSlidingText', JSON.stringify(response.data.data.settings.second_white_sliding_text))
                localStorage.setItem('secondAmberSlidingText', JSON.stringify(response.data.data.settings.second_amber_sliding_text))
                localStorage.setItem('gameCurrency', JSON.stringify(response.data.data.settings.currency));
                localStorage.setItem('firstBanner', JSON.stringify(response.data.data.settings.enable_first_banner));
                localStorage.setItem('firstBannerImage', JSON.stringify(response.data.data.settings.first_banner_image));
                localStorage.setItem('secondBanner', JSON.stringify(response.data.data.settings.enable_second_banner));
                localStorage.setItem('secondBannerImage', JSON.stringify(response.data.data.settings.second_banner_image));
                localStorage.setItem('dashboardUrl', JSON.stringify(response.data.data.settings.dashboard_site_url));
                localStorage.setItem('clientSiteName', JSON.stringify(response.data.data.settings.client_site_name));
                localStorage.setItem('clientSiteUrl', JSON.stringify(response.data.data.settings.client_site_url));
                localStorage.setItem('cookiePolicyInfo', JSON.stringify(response.data.data.settings.cookie_policy));
                localStorage.setItem('terms', JSON.stringify(response.data.data.settings.terms));
                localStorage.setItem('firstBetAmount', JSON.stringify(response.data.data.settings.default_bet_value));
                localStorage.setItem('secondBetAmount', JSON.stringify(response.data.data.settings.default_bet_value));
                localStorage.setItem('autoplayEnabled', JSON.stringify(response.data.data.settings.autoplay));
                localStorage.setItem('aboutGameInfo', JSON.stringify(response.data.data.settings.about_game_info));
                localStorage.setItem('provablyFairInfo', JSON.stringify(response.data.data.settings.provably_fair_info));
                localStorage.setItem('bettingLicenseInfo', JSON.stringify(response.data.data.settings.betting_license_info));
                localStorage.setItem('logo', JSON.stringify(response.data.data.settings.client_site_logo));
                localStorage.setItem('defaultBetValue', JSON.stringify(response.data.data.settings.default_bet_value));
                localStorage.setItem('maximumMultiplier', JSON.stringify(response.data.data.settings.maximum_multiplier_value));
                localStorage.setItem('maximumWinAmount', JSON.stringify(response.data.data.settings.maximum_win));
                localStorage.setItem('minimumBet', JSON.stringify(response.data.data.settings.minimum_bet));
                localStorage.setItem('autoplayAmount', JSON.stringify(response.data.data.settings.default_bet_value));
                localStorage.setItem('maximumBet', JSON.stringify(response.data.data.settings.maximum_bet));
                localStorage.setItem('betInputInterval', JSON.stringify(response.data.data.settings.bet_input_interval));
                localStorage.setItem('firstPresetInput', JSON.stringify(response.data.data.settings.first_preset_input));
                localStorage.setItem('secondPresetInput', JSON.stringify(response.data.data.settings.second_preset_input));
                localStorage.setItem('thirdPresetInput', JSON.stringify(response.data.data.settings.third_preset_input));
                localStorage.setItem('fourthPresetInput', JSON.stringify(response.data.data.settings.fourth_preset_input));
                localStorage.setItem('fifthPresetInput', JSON.stringify(response.data.data.settings.fifth_preset_input));
                localStorage.setItem('sixthPresetInput', JSON.stringify(response.data.data.settings.sixth_preset_input));
                localStorage.setItem('seventhPresetInput', JSON.stringify(response.data.data.settings.seventh_preset_input));
                localStorage.setItem('eighthPresetInput', JSON.stringify(response.data.data.settings.eighth_preset_input));
                if (response.data.data.settings.hide_my_bets_section == true) {
                    localStorage.setItem('hideMyBetsSection', JSON.stringify(true));
                } else {
                    localStorage.setItem('hideMyBetsSection', JSON.stringify(false));
                }
                localStorage.setItem('directDepositGateway', JSON.stringify(response.data.data.settings.direct_deposit_gateway));
                localStorage.setItem('minimumDeposit', JSON.stringify(response.data.data.settings.minimum_deposit));
                localStorage.setItem('maximumDeposit', JSON.stringify(response.data.data.settings.maximum_deposit));
                localStorage.setItem('minimumWithdrawal', JSON.stringify(response.data.data.settings.minimum_withdrawal));
                localStorage.setItem('maximumWithdrawal', JSON.stringify(response.data.data.settings.maximum_withdrawal));
                localStorage.setItem('manualPayment', JSON.stringify(response.data.data.settings.manual_payment));
                localStorage.setItem('mpesaPayment', JSON.stringify(response.data.data.settings.mpesa_payment));
                localStorage.setItem('stripePayment', JSON.stringify(response.data.data.settings.stripe_payment));
                localStorage.setItem('paypalPayment', JSON.stringify(response.data.data.settings.paypal_payment));
                localStorage.setItem('dusupayPayment', JSON.stringify(response.data.data.settings.dusupay_payment));
                localStorage.setItem('wingipayPayment', JSON.stringify(response.data.data.settings.wingipay_payment));
                localStorage.setItem('directDeposit', JSON.stringify(response.data.data.settings.direct_deposit));
                localStorage.setItem('directDepositInstructions', JSON.stringify(response.data.data.settings.direct_deposit_instructions));
                localStorage.setItem('aboutAffiliateMarketing', JSON.stringify(response.data.data.settings.about_affiliate_marketing));
                localStorage.setItem('stripePublishableKey', JSON.stringify(response.data.data.settings.stripe_publishable_key));
                localStorage.setItem('registrationType', JSON.stringify(response.data.data.settings.registration_type));
                localStorage.setItem('manualPaymentInstructions', JSON.stringify(response.data.data.settings.manual_payment_instructions));
                localStorage.setItem('withdrawalInstructions', JSON.stringify(response.data.data.settings.withdrawal_instructions));
                localStorage.setItem('mpesaPaymentInstructions', JSON.stringify(response.data.data.settings.mpesa_payment_instructions));
                localStorage.setItem('dusupayPaymentInstructions', JSON.stringify(response.data.data.settings.dusupay_payment_instructions));
                localStorage.setItem('directWithdrawal', JSON.stringify(response.data.data.settings.direct_withdrawal));
                localStorage.setItem('directWithdrawalGateway', JSON.stringify(response.data.data.settings.direct_withdrawal_gateway));

                localStorage.setItem('enableDepositSharing', JSON.stringify(response.data.data.settings.enable_deposit_sharing));
                localStorage.setItem('firstLevelAffiliate', JSON.stringify(response.data.data.settings.first_level_affiliate));
                localStorage.setItem('firstLevelAffiliatePercentage', JSON.stringify(response.data.data.settings.first_level_affiliate_percentage));
                localStorage.setItem('secondLevelAffiliate', JSON.stringify(response.data.data.settings.second_level_affiliate));
                localStorage.setItem('secondLevelAffiliatePercentage', JSON.stringify(response.data.data.settings.second_level_affiliate_percentage));
                localStorage.setItem('thirdLevelAffiliate', JSON.stringify(response.data.data.settings.third_level_affiliate));
                localStorage.setItem('thirdLevelAffiliatePercentage', JSON.stringify(response.data.data.settings.third_level_affiliate_percentage));
                localStorage.setItem('minimumAffiliateWithdrawal', JSON.stringify(response.data.data.settings.minimum_affiliate_withdrawal));
                localStorage.setItem('funModeAccountBalance', JSON.stringify(response.data.data.settings.fun_mode_account_balance));
                localStorage.setItem('countryCode', JSON.stringify(response.data.data.settings.country));
                localStorage.setItem('sportsBookName', JSON.stringify(response.data.data.settings.sports_book_name));
                localStorage.setItem('sportsBookUrl', JSON.stringify(response.data.data.settings.sports_book_url));

             //   console.log(response.data.data.settings.game_mode);

                dispatch(setEncryptionKey(response.data.data.settings.encryption_key));
                dispatch(setGameMode(response.data.data.settings.game_mode));
                dispatch(setLiveBets(response.data.data.settings.live_bets));
                dispatch(setPreviousGames(response.data.data.settings.previous_games));
                dispatch(setMessages(response.data.data.settings.messages));
                dispatch(setAccountBalance(response.data.data.settings.fun_mode_account_balance));



                var dateobj = new Date();
                var dateObject = dateobj.getFullYear();
                localStorage.setItem('currentYear', JSON.stringify(dateObject));

                //set music
                if (localStorage.getItem('music') !== null) {
                    dispatch(setMusic(JSON.parse(localStorage.getItem('music'))));
                } else {
                    dispatch(setMusic(true));
                    localStorage.setItem('music', JSON.stringify(true));
                }

                //setVolume
                if (localStorage.getItem('volume') !== null) {
                    dispatch(setVolume(JSON.parse(localStorage.getItem('volume'))));
                } else {
                    dispatch(setVolume(0.3));
                    localStorage.setItem('volume', JSON.stringify(0.3));
                }




                if (localStorage.getItem('crashToken') !== null) {

                    axios.get(`${baseUrl}/api/authUser`,
                        {
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                            },
                        })
                        .then((response) => {
                            dispatch(setMyBets(response.data.data.myBets));
                            dispatch(setUserId(response.data.data.user.id));
                            dispatch(setEmail(response.data.data.user.email));
                            if (response.data.data.user.name !== null) {
                                dispatch(setName(response.data.data.user.name));
                            }
                            if (response.data.data.user.username !== null) {
                                dispatch(setUsername(response.data.data.user.username));
                            }
                            if (response.data.data.user.phone !== null) {
                                dispatch(setPhone(response.data.data.user.phone));
                            }
                            dispatch(setAccountBalance(response.data.data.user.wallet));
                            localStorage.setItem('marketerStatus', JSON.stringify(response.data.data.user.marketer));
                            dispatch(setAuthentication(true));
                            setScreenLoading(() => false);

                        }).catch((err) => {
                            if (!err?.response) {
                                errorNotify('No Server Response');
                            } else {
                                localStorage.removeItem("crashToken");
                                errorNotify(err.response.data.message);
                            }

                            setScreenLoading(() => false);



                        });
                } else {
                    setScreenLoading(() => false);


                }
            }).catch((err) => {
                if (!err?.response) {
                    errorNotify('No Server Response');
                } else {
                    errorNotify('Something went wrong!');
                }

                setScreenLoading(() => false);



            });


    }, [baseUrl])









    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }




    return (
        <Context.Provider value={[
            setPreviousGames,


        ]}>
            {screenLoading ? (
                <div className="h-screen flex items-center justify-center">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#1c81dd"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            ) : (

                <div className="min-h-screen">
                    <CrashGame


                    />

                </div>
            )}
        </Context.Provider>
    )
}




export default Constants




