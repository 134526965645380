import { useRef, useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography, Alert } from "@material-tailwind/react";
import axios from 'axios';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Context } from './Constants';


import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { setAccountBalance } from "../features/accountBalanceSlice";
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;

const TRANSACTION_AMOUNT_REGEX = /(?!^$)([^\s])/;
const PHONE_NUMBER_REGEX = /(?!^$)([^\s])/;


const MpesaPayment = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const authentication = useSelector((state) => state.authentication.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const userId = useSelector((state) => state.userId.value);
    const myBets = useSelector((state) => state.myBets.value);
    const username = useSelector((state) => state.username.value);
    const phone = useSelector((state) => state.phone.value);
    const email = useSelector((state) => state.email.value);
  
    const transactionPhoneRef = useRef();
    const transactionAmountRef = useRef();
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState([]);

    const [transactionPhone, setTransactionPhone] = useState(phone);
    const [validTransactionPhone, setValidTransactionPhone] = useState(false);
    const [transactionPhoneFocus, setTransactionPhoneFocus] = useState(false);

    const [transactionAmount, setTransactionAmount] = useState('');
    const [validTransactionAmount, setValidTransactionAmount] = useState(false);
    const [transactionAmountFocus, setTransactionAmountFocus] = useState(false);

    const [mpesaPaymentConfirmation, setMpesaPaymentConfirmation] = useState(false);

    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const directDeposit = JSON.parse(localStorage.getItem('directDeposit'));
    const directDepositInstructions = JSON.parse(localStorage.getItem('directDepositInstructions'));
    const withdrawalInstructions = JSON.parse(localStorage.getItem('withdrawalInstructions'));
    const mpesaPaymentInstructions = JSON.parse(localStorage.getItem('mpesaPaymentInstructions'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const countryCode = JSON.parse(localStorage.getItem('countryCode'));


  
    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
      }

    // useEffect(() => {
    //     transactionPhoneRef.current.focus();
    // }, [])

    // useEffect(() => {
    //     transactionAmountRef.current.focus();
    // }, [])

    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    // useEffect(() => {
    //     setValidTransactionPhone(PHONE_NUMBER_REGEX.test(transactionPhone));
    // }, [transactionPhone])

    // useEffect(() => {

    //     if (transactionPhone.trim().slice(0, 3) == '254') {
    //         if (transactionPhone.trim().length !== 12) {
    //             setValidTransactionPhone(() => false);
    //         } else {
    //             setValidTransactionPhone(() => true);
    //         }
    //     } else {
    //         if (transactionPhone.trim().length < 10) {
    //             setValidTransactionPhone(() => false);
    //         } else {
    //             setValidTransactionPhone(() => true);
    //         }
    //     }

    // }, [transactionPhone])

    useEffect(() => {
        if (transactionAmount >= Number(minimumDeposit) && transactionAmount <= Number(maximumDeposit)) {
            setValidTransactionAmount(true);
        } else {
            setValidTransactionAmount(false);
        }
    }, [transactionAmount, minimumDeposit, maximumDeposit])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (directDeposit == true) {
            setTransactionPhone(() => phone);
        }
        props.setMpesaPaymentProcessing(() => true);
        await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
        const response = await axios.post(`${baseUrl}/api/initiateMpesaOnlinePayment`,
            { userId: userId, phone: transactionPhone, amount: transactionAmount },
            {
                headers: {
                    'Accept': 'application/vnd.api+json',
                    'Content-Type': 'application/vnd.api+json',
                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                },
            }
        )



        if (marketerStatus == true) {
            dispatch(setAccountBalance(response?.data.data.account_balance));
            props.handleCloseAllPaymentMethods();
            props.handleOpenDeposit();
            props.setMpesaPaymentProcessing(() => false);
            setMpesaPaymentConfirmation(() => false);
            successNotify(response?.data.data.message);

        } else if (response?.data.errorCode != null) {
            props.setMpesaPaymentProcessing(() => false);
            errorNotify('Transaction Failed');
            setErrMsg('Transaction Failed');
            errRef.current.scrollIntoView();
        } else {
            props.setMpesaPaymentProcessing(() => false);
            props.setCheckoutRequestID(() => response?.data.CheckoutRequestID);
            setMpesaPaymentConfirmation(() => true);
            successNotify('Transaction initiated successfully.');
        }

    }




    return (

        <div>
            {mpesaPaymentConfirmation === false &&
                <div>
                    {directDeposit == false &&
                        <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: mpesaPaymentInstructions }} />
                    }
                    {directDeposit == true &&
                        <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: directDepositInstructions }} />
                    }

                    <Card className="mx-auto w-full max-w-[24rem] my-5">
                        <CardBody className="pt-0 flex flex-col gap-4">

                            <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                                {errMsg !== '' &&
                                    <li>{errMsg}<br /></li>
                                }

                            </ul>

                            {/* <PhoneInput
                                variant="outlined"
                                label="Phone Number"
                                size="lg"
                                type="text"
                                id="transactionPhone"
                                ref={transactionPhoneRef}
                                country={countryCode}
                                enableSearch={true}
                                autoComplete="off"
                                aria-invalid={validTransactionPhone ? "false" : "true"}
                                aria-describedby="transactionPhoneNote"
                                onChange={(e) => setTransactionPhone(e)}
                                value={transactionPhone}
                                onFocus={() => setTransactionPhoneFocus(true)}
                                onBlur={() => setTransactionPhoneFocus(false)}
                                error={transactionPhone && !validTransactionPhone ? true : false}
                                success={validTransactionPhone ? true : false}
                                required

                            />

                            



                            <Typography
                                id="transactionPhoneNote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal" + transactionPhoneRef && transactionPhone && !validTransactionPhone ? "flex" : "hidden"}
                            >
                                Must be a valid phone number.! <br />
                            </Typography> */}

                            <Input
                                variant="outlined"
                                label="Amount"
                                size="lg"
                                type="number"
                                id="amount"
                                ref={transactionAmountRef}
                                autoComplete="off"
                                aria-invalid={validTransactionAmount ? "false" : "true"}
                                aria-describedby="transactionAmountNote"
                                onChange={(e) => setTransactionAmount(e.target.value)}
                                value={transactionAmount}
                                onFocus={() => setTransactionAmountFocus(true)}
                                onBlur={() => setTransactionAmountFocus(false)}
                                error={transactionAmount && !validTransactionAmount ? true : false}
                                success={validTransactionAmount ? true : false}
                                required
                            />
                            <Typography
                                id="transactionAmountNote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal" + transactionAmountRef && transactionAmount && !validTransactionAmount ? "flex" : "hidden"}
                            >
                                Must be greater than {minimumDeposit}! <br />
                                Must be less than {maximumDeposit}! <br />
                            </Typography>



                        </CardBody>
                        <CardFooter className="pt-0">
                            {props.mpesaPaymentProcessing === true &&
                                <Button onClick={handleSubmit} disabled={!validTransactionPhone || !validTransactionAmount || props.mpesaPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                    <span className="flex items-center justify-center gap-2 mx-auto">
                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </span>
                                </Button>
                            }
                            {props.mpesaPaymentProcessing === false &&
                                <>
                                    {directDeposit == false &&
                                        <Button onClick={handleSubmit} disabled={!validTransactionPhone || !validTransactionAmount || props.mpesaPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                    {directDeposit == true &&
                                        <Button onClick={handleSubmit} disabled={!validTransactionAmount || props.mpesaPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                </>
                            }
                        </CardFooter>
                    </Card>
                    <div className="grid grid-cols-2 p-2 gap-2 text-sm w-full text-orange-200 border rounded">
                        <span className="w-full">
                            Minimum Deposit: <b>{formatAmount(minimumDeposit)}</b>
                        </span>
                        <span className="w-full">
                            Maximum Deposit: <b>{formatAmount(maximumDeposit)}</b>
                        </span>
                    </div>
                </div>
            }


            {mpesaPaymentConfirmation === true &&
                <div>
                    <Alert icon={<InformationCircleIcon className="h-7 w-7" />} color="amber">
                        After completing the payment on your phone, kindly click the button below to confirm.
                    </Alert>


                    {props.mpesaPaymentProcessing === true &&
                        <Button onClick={props.confirmMpesaPayment} disabled={props.mpesaPaymentProcessing ? true : false} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {props.mpesaPaymentProcessing === false &&
                        <Button onClick={props.confirmMpesaPayment} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                            Confirm Payment
                        </Button>
                    }
                </div>
            }


        </div>
    )
}

export default MpesaPayment